import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit {

  token;
  baseUrl: string = environment.COMMON_API_ENDPOINT;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get("token")
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ` + this.token);

    if (this.token) {
      this.http.get(this.baseUrl + "/tradeToken", { headers }).subscribe(data => {
        var currentUser = JSON.stringify(data);
        var user = JSON.parse(currentUser).user;
        if (null==user.roles || user.roles.length==0)
          this.router.navigate(['/error']);
        else {
          localStorage.setItem('currentUser', currentUser);
          this.router.navigate(['/home']);
        }
      }, error => {
        console.log("Error trade token from server.")
      })
    }
  }

}
