<div class="logout">
  <div class="inner-box">
      <img src="../../assets/image/logout-page.png"/>
      <p class="tip">You have successfully logged out! </p>
      <div class="btn btn-default" (click)="login()">Login again</div>
  </div>

</div>


