import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationServiceService {
  constructor(private http: HttpClient) {}
  baseUrl: string = environment.COMMON_API_ENDPOINT;

  login(username: string, password: string) {
    return this.http.post<any>(this.baseUrl+'/signin', { username: username, password: password }).pipe(map(res => {
      if (res&&!res.error) {
        localStorage.setItem('currentUser', JSON.stringify(res));
      } else {
        // console.log(res);
      }
      return res
    }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentConfig');
  }
}
