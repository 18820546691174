
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http'
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class MyHttpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf("/tradeToken")==-1 && request.url.indexOf("/tpl")==-1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ` + JSON.parse(localStorage.getItem("currentUser")).jwt.token
        }
      });
      return next.handle(request)
        .pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
            }
          }, error => {
            console.error(error);
          })
        )
    } 
    else {
      return next.handle(request)
    }
  }

}
