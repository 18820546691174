import {Injectable} from '@angular/core'
import {Router,CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot}from '@angular/router';
import {AuthenticationServiceService} from './services/authentication-service.service';
import {Observable,of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate{
    baseUrl: string = environment.COMMON_API_ENDPOINT;
    constructor(private router: Router, private http: HttpClient, private authService:AuthenticationServiceService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean>  | boolean{
        if(localStorage.getItem('currentUser')){
            if(!localStorage.getItem('currentConfig')){
                return this.http.get<any>(this.baseUrl+'/admin/config/init').pipe(map(res => {
                    if (!res.error) {
                        let formatData={}
                        res.map(item => {
                            formatData[item.configkey] = item.value
                        })
                        localStorage.setItem('currentConfig', JSON.stringify(formatData));
                        return true;
                    } 
                }))
            } else {
                return true;
            }
        }
        this.router.navigate(['/login']);
        // window.location.href = route.data['externalUrl'];
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
