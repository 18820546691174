import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private readonly route: ActivatedRoute,
    private readonly router: Router) { }

  ngOnInit() {
  }


  login(){
    this.router.navigate(['login'])
  }


}
