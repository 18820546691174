import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component'; // the bootstrap component
import { animation, style, animate } from '@angular/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n'; 
import { MyHttpInterceptor } from './my-http-interceptor';  // http interceptor
import { ErrorInterceptor } from './error-interceptor'  // http interceptor
import { WINDOW_PROVIDERS } from './window-ref'
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth-guard';
import { RedirectGuard } from './redirect-guard';
import { SsoComponent } from './sso/sso.component';
import { LogoutComponent } from './logout/logout.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { HttpErrorHandlerService } from './services/http-error-handler.service';
import { NgZorroPublicModule } from './shared/ng-zorro-public/ng-zorro-public.module';

@NgModule({
  declarations: [
    AppComponent,
    SsoComponent,
    LogoutComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgZorroPublicModule
  ],
  providers: [
    AuthGuard,
    RedirectGuard,
    WINDOW_PROVIDERS,
    HttpErrorHandlerService,
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export const fadeIn = animation([
  style({
    opacity: 0,

  }),
  animate(
    '1000ms',
    style({
      'animation-timing-function': 'ease-in-out',
      opacity: 1,
    })
  )
]);
