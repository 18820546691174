export const environment = {
  production: true,
  COMMON_API_ENDPOINT: "https://uem.qa.iops.int.sap/common-svc",
  ES_API_ENDPOINT: "https://uem.qa.iops.int.sap/elasticsearch-internal",
  CIO_API_ENDPOINT: "https://iops-q.azure-api.net/api/cio",
  AZURE_FUNC_ENDPOINT: "https://iops-q.azure-api.net/api/function",
  // SSO_URL:'https://ycloudtest.accounts400.ondemand.com/saml2/idp/sso?sp=iops-itg',
  SSO_URL: "https://ycloud.accounts.ondemand.com/saml2/idp/sso?sp=iops-suite",
  SAC_TENANT_NAME: "integratedops-qa.eu10.hcs.cloud.sap",
  SAC_TENANT_ID: "b47f7",
  SAC_STORY_ID: "A4906506591D8D41D02DD69A9AEC208A",
};
