import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { PlusOutline, 
  UploadOutline, 
  DownOutline, 
  DownloadOutline, 
  DeleteOutline,
  SearchOutline,
  DeleteTwoTone,
  EyeOutline,
  LoadingOutline,
  FilterOutline,
  CloseCircleFill,
  ExclamationCircleOutline
} from '@ant-design/icons-angular/icons';
const icons: IconDefinition[] = [ 
  PlusOutline, 
  UploadOutline,
  DownOutline, 
  DownloadOutline, 
  DeleteOutline,
  SearchOutline,
  DeleteTwoTone,
  EyeOutline,
  LoadingOutline,
  FilterOutline,
  CloseCircleFill,
  ExclamationCircleOutline
 ];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NzGridModule,
    NzIconModule.forRoot(icons),
    NzMessageModule,
    NzTableModule,
    NzTabsModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzModalModule,
    NzSelectModule,
    NzFormModule,
    NzInputModule,
    NzDropDownModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzRadioModule,
    NzSpinModule,
    NzAlertModule
  ],
  exports:[
    NzGridModule,
    NzIconModule,
    NzMessageModule,
    NzTableModule,
    NzTabsModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzModalModule,
    NzSelectModule,
    NzFormModule,
    NzInputModule,
    NzDropDownModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzRadioModule,
    NzSpinModule,
    NzAlertModule
  ]
})
export class NgZorroPublicModule { }
